import React, { useState, useEffect } from 'react';
import './AdminDashboard.css'; // Stil dosyasını ekledik

function AdminDashboard() {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    username: '',
    password: '1',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: 'user',
    company: 'Hilalsan'
  });
  const [editUser, setEditUser] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  // Kullanıcıları getirme
  const fetchUsers = async () => {
    try {
      const response = await fetch('/api/users/getall', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Kullanıcılar alınamadı');
      const usersData = await response.json();
      setUsers(usersData);
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  // Yeni kullanıcı ekleme veya kullanıcı güncelleme
  const handleSaveUser = async () => {
    const url = editUser
      ? `api/users/update/${editUser._id}`
      : 'api/users/create';

    const method = editUser ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(editUser || newUser)
      });
      if (!response.ok) throw new Error('İşlem gerçekleştirilemedi');
      await fetchUsers();
      setNewUser({
        username: '',
        password: '1',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: 'user',
        company: 'Hilalsan'
      });
      setEditUser(null);
      setShowForm(false);
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  // Kullanıcı silme
  const handleDeleteUser = async (userId) => {
    try {
      const response = await fetch(`api/users/delete/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Kullanıcı silinemedi');
      await fetchUsers();
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  // İlk başta kullanıcıları getir
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard - Kullanıcı Yönetimi</h2>

      {/* Yeni Kullanıcı Ekle Butonu */}
      <button className="add-user-button" onClick={() => { setShowForm(true); setEditUser(null); }}>
        Yeni Kullanıcı Ekle
      </button>

      {/* Kullanıcı ekleme ve güncelleme formu */}
      {showForm && (
        <div className="user-form">
          <h3>{editUser ? 'Kullanıcıyı Güncelle' : 'Yeni Kullanıcı Ekle'}</h3>
          <input
            type="text"
            placeholder="Kullanıcı Adı"
            value={editUser ? editUser.username.toLowerCase() : newUser.username.toLowerCase()}
            onChange={(e) =>
              editUser
                ? setEditUser({ ...editUser, username: e.target.value.toLowerCase() })
                : setNewUser({ ...newUser, username: e.target.value.toLowerCase() })
            }
          />
          <input
            type="text"
            placeholder="Ad"
            value={editUser ? editUser.firstName : newUser.firstName}
            onChange={(e) =>
              editUser
                ? setEditUser({ ...editUser, firstName: e.target.value })
                : setNewUser({ ...newUser, firstName: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Soyad"
            value={editUser ? editUser.lastName : newUser.lastName}
            onChange={(e) =>
              editUser
                ? setEditUser({ ...editUser, lastName: e.target.value })
                : setNewUser({ ...newUser, lastName: e.target.value })
            }
          />
          <input
            type="email"
            placeholder="E-posta"
            value={editUser ? editUser.email : newUser.email}
            onChange={(e) =>
              editUser
                ? setEditUser({ ...editUser, email: e.target.value })
                : setNewUser({ ...newUser, email: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Telefon"
            value={editUser ? editUser.phone : newUser.phone}
            onChange={(e) =>
              editUser
                ? setEditUser({ ...editUser, phone: e.target.value })
                : setNewUser({ ...newUser, phone: e.target.value })
            }
          />

          {/* Rol seçimi dropdown */}
          <select
            value={editUser ? editUser.role : newUser.role}
            onChange={(e) =>
              editUser
                ? setEditUser({ ...editUser, role: e.target.value })
                : setNewUser({ ...newUser, role: e.target.value })
            }
          >
            <option value="user">Kullanıcı</option>
            <option value="admin">Admin</option>
          </select>

          <input
            type="text"
            placeholder="Şirket"
            value={editUser ? editUser.company : newUser.company}
            disabled
          />

          <button className="save-button" onClick={handleSaveUser}>
            {editUser ? 'Güncelle' : 'Ekle'}
          </button>

          <button className="cancel-button" onClick={() => setShowForm(false)}>İptal</button>
        </div>
      )}

      {/* Kullanıcı listesi kartlarla */}
      <h3>Kullanıcı Listesi</h3>
      <div className="user-cards">
        {users.map((user) => (
          <div key={user._id} className="user-card">
            <h4>{user.username}</h4>
            <p>Ad: {user.firstName} {user.lastName}</p>
            <p>E-posta: {user.email}</p>
            <p>Telefon: {user.phone}</p>
            <p>Rol: {user.role}</p>
            <p>Şirket: {user.company}</p>
            <div className="user-card-buttons">
              <button className="edit-button" onClick={() => { setShowForm(true); setEditUser(user); }}>
                Düzenle
              </button>
              <button className="delete-button" onClick={() => handleDeleteUser(user._id)}>
                Sil
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminDashboard;
